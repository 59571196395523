<template>
  <div class="common-layout">
    <el-container>
      <el-aside class="common-aside">
        <div class="common-logo">
          <!-- <img src="@/assets/img/logo.png" /> -->
          <div class="fb" v-if="merchant">文档管理</div>
        </div>
        <el-menu :default-active="activeIndex" router>
          <el-menu-item
            index="MerchantFileDocument"
            :route="{ name: 'MerchantFileDocument' }"
          >
            <el-icon>
              <DataAnalysis/>
            </el-icon>
            <template #title>文档</template>
          </el-menu-item>
          <el-menu-item
            index="MerchantWebDocument"
            :route="{ name: 'MerchantWebDocument' }"
          >
            <el-icon>
              <Link/>
            </el-icon>
            <template #title>网站</template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-main>
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { computed, onMounted } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import { Setting, Shop, SwitchButton, Tools } from '@element-plus/icons-vue'
import store from '@/store'
import Empty from "@/components/Empty.vue";

export default {
  components: { Empty },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const activeIndex = computed(() => {
      return route.name
    })

    const removeParam = (key) => {
      sessionStorage.removeItem(key)
    }
    const logout = () => {
      sessionStorage.removeItem('merchantId')
      router.replace({
        name: 'Admin',
      })
    }

    const merchant = computed(() => {
      return store.getters.merchant
    })

    const goAdminEdit = () => {
      router.push({
        name: 'AdminEdit',
      })
    }

    onMounted(() => {
    })
    return {
      SwitchButton,
      Setting,
      Shop,
      Tools,
      activeIndex,
      removeParam,
      logout,
      merchant,
      goAdminEdit,
    }
  },
}
</script>
<style>
.common-layout,
.common-layout .el-container {
  height: 100%;
}

.common-aside {
  width: 200px;
  border-right: 1px solid #dee2e6;
}

.common-layout .el-menu {
  border-right: 0px;
}

.common-logo {
  padding: 20px 0;
  text-align: center;
}

.common-logo img {
  width: 50%;
}
</style>

<template>
  <div class="common-layout">
    <el-container>
      <el-aside class="common-aside">
        <div class="common-logo">
          <!-- <img src="@/assets/img/logo.png" /> -->
          <div class="fb" v-if="merchant">{{ merchant.name }}</div>
        </div>
        <el-menu :default-active="activeIndex" router>
          <el-menu-item
            index="MerchantDashboard"
            :route="{ name: 'MerchantDashboard' }"
          >
            <el-icon>
              <DataAnalysis/>
            </el-icon>
            <template #title>Dashboard</template>
          </el-menu-item>
          <el-menu-item
            index="MerchantSetting"
            :route="{ name: 'MerchantSetting' }"
          >
            <el-icon>
              <Setting/>
            </el-icon>
            <template #title>设置</template>
          </el-menu-item>
          <el-menu-item
            index="MerchantPrompt"
            :route="{ name: 'MerchantPrompt' }"
          >
            <el-icon>
              <Memo/>
            </el-icon>
            <template #title>提示词</template>
          </el-menu-item>
          <!--          <el-menu-item-->
          <!--            index="SystemConfig"-->
          <!--            :route="{ name: 'SystemConfig' }"-->
          <!--          >-->
          <!--            <el-icon>-->
          <!--              <Key/>-->
          <!--            </el-icon>-->
          <!--            <template #title>关键词匹配</template>-->
          <!--          </el-menu-item>-->
          <el-menu-item
            index="MerchantDocument"
            :route="{ name: 'MerchantFileDocument' }"
          >
            <el-icon>
              <Document/>
            </el-icon>
            <template #title>文档管理</template>
          </el-menu-item>
          <el-menu-item
            index="MerchantMultilingual"
            :route="{ name: 'MerchantMultilingual' }"
          >
            <el-icon>
              <Setting/>
            </el-icon>
            <template #title>多语言设置</template>
          </el-menu-item>
          <el-menu-item
            index="MerchantWelcome"
            :route="{ name: 'MerchantWelcome' }"
          >
            <el-icon>
              <Sunny/>
            </el-icon>
            <template #title>欢迎词</template>
          </el-menu-item>
          <el-menu-item
            index="MerchantCommonQuestion"
            :route="{ name: 'MerchantCommonQuestion' }"
          >
            <el-icon>
              <Notebook/>
            </el-icon>
            <template #title>常见问题</template>
          </el-menu-item>
          <el-menu-item
            index="MerchantChatHistory"
            :route="{ name: 'MerchantChatHistory' }"
          >
            <el-icon>
              <ChatDotRound/>
            </el-icon>
            <template #title>历史会话</template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header class="common-header">
          <div class="clearfix">
            <!-- <div class="fr mt20 pointer cRed">退出</div> -->
            <div class="fr pt15">
              <el-button
                type="primary"
                circle
                text
                :icon="SwitchButton"
                @click="logout"
              ></el-button>
            </div>
          </div>
        </el-header>
        <el-main>
          <Empty v-if="merchant === null || config === {}"></Empty>
          <router-view v-else/>
        </el-main>
        <!-- <el-footer>Footer</el-footer> -->
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { computed, onMounted } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import { Setting, Shop, SwitchButton, Tools } from '@element-plus/icons-vue'
import store from '@/store'
import Empty from "@/components/Empty.vue";

export default {
  components: { Empty },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const activeIndex = computed(() => {
      return route.name
    })

    const removeParam = (key) => {
      sessionStorage.removeItem(key)
    }
    const logout = () => {
      store.commit('setMerchant', null)
      sessionStorage.removeItem('merchantId')
      router.replace({
        name: 'MerchantList',
      })
    }

    const config = computed(() => {
      return store.getters.config
    })

    const merchant = computed(() => {
      return store.getters.merchant
    })

    const goAdminEdit = () => {
      router.push({
        name: 'AdminEdit',
      })
    }

    onMounted(() => {
      const merchantId = sessionStorage.getItem('merchantId') || ''
      if (merchantId === '') {
        router.replace({ name: 'Admin' })
        return
      }
      store.dispatch('setConfig')
      store.dispatch('setMerchant', merchantId)
    })
    return {
      SwitchButton,
      Setting,
      Shop,
      Tools,
      activeIndex,
      removeParam,
      logout,
      merchant,
      goAdminEdit,
      config,
    }
  },
}
</script>
<style>
.common-layout,
.common-layout .el-container {
  height: 100%;
}

.common-header {
  border-bottom: 1px solid #dee2e6;
  background: #f8f9fa;
}

.common-aside {
  width: 200px;
  border-right: 1px solid #dee2e6;
}

.common-layout .el-menu {
  border-right: 0px;
}

.common-logo {
  padding: 20px 0;
  text-align: center;
}

.common-logo img {
  width: 50%;
}
</style>
